@use "sass:math";

@use "@sas-te/alfabeto-tokens/dist/tokens";

.actions {
  display: flex;
  justify-content: space-between;
  padding: tokens.$size-xs math.div(tokens.$grid-gutter-width, 2);
  border-top: 1px solid tokens.$color-ink-lightest;
}

.confirm {
  margin-left: tokens.$size-s;
}

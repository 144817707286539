@use "@sas-te/alfabeto-tokens/dist/tokens";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    margin-top: tokens.$size-l;
  }

  &:not(:first-child) {
    margin: tokens.$size-s 0;
  }
}

.title {
  font-size: tokens.$font-size-m;
  line-height: tokens.$line-height-heading;
  color: tokens.$color-ink;
}

.description {
  font-size: tokens.$font-size-s;
  line-height: tokens.$line-height-text;
  color: tokens.$color-ink-light;
  margin-top: tokens.$size-xxs
}

.separator {
  border-top: 1px solid tokens.$color-ink-lightest;
}

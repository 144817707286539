@use "@sas-te/alfabeto-tokens/dist/tokens";

.container {
  display: flex;
  justify-content: flex-end;
}

.hasMargin {
  margin: tokens.$size-m 0;
}

@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: tokens.$size-s tokens.$size-xs;
  font-size: tokens.$font-size-s;
  font-weight: tokens.$font-weight-medium;
  color: tokens.$color-main;
  text-decoration: none;
  letter-spacing: .4px;
  white-space: nowrap;
  cursor: pointer;
  background-color: tokens.$color-white;
  border: 0;
  border-bottom: 1px solid transparent;
  border-radius: 0;

  &:hover,
  &:focus {
    border-bottom-color: tokens.$color-main;
    outline: none;
  }

  @include media-queries.mq-xl--mf() {
    align-items: center;
    justify-content: center;
    padding: 0 tokens.$size-xs;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  @include media-queries.mq-xl--mf() {
    transform: rotate(90deg);
  }
}

@use "@sas-te/alfabeto-tokens/dist/tokens";

:global(.Toastify__toast--info) {
  .closeButton {
    color: tokens.$color-primary-dark;
  }
}

:global(.Toastify__toast--success) {
  .closeButton {
    color: tokens.$color-success-dark;
  }
}

:global(.Toastify__toast--warning) {
  .closeButton {
    color: tokens.$color-warning-dark;
  }
}

:global(.Toastify__toast--error) {
  .closeButton {
    color: tokens.$color-danger-dark;
  }
}

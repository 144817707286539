@use "@sas-te/alfabeto-tokens/src/scss/functions";
@use "@sas-te/alfabeto-tokens/dist/tokens";

.example {
  margin-bottom: tokens.$size-xs;
  font-size: tokens.$font-size-s;
  font-weight: tokens.$font-weight-medium;
  line-height: tokens.$line-height-reset;
  color: tokens.$color-ink-lightest;

  label + & {
    margin-top: -#{tokens.$size-xxs};
  }
}

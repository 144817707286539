@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/flex";
@use "@sas-te/alfabeto-tokens/src/scss/functions";
@use "@sas-te/alfabeto-tokens/dist/tokens";

.header {
  position: relative;
  border-bottom: 1px solid tokens.$color-ink-lightest;
  margin-bottom: tokens.$size-l;

  &::before {
    display: block;
    width: 100%;
    height: 1px;
    content: "";
  }
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: tokens.$size-xs 0;
  border-bottom: 1px solid rgba(tokens.$color-ink-lightest, .75);

  .lightTheme & {
    padding: tokens.$size-s 0 tokens.$size-xs 0;
    border-bottom: 0;
  }
}

.wrapper {
  position: relative;

  .primaryTheme & {
    padding-bottom: tokens.$size-s;
    margin-top: tokens.$size-s;
  }

  .lightTheme & {
    padding-bottom: tokens.$size-m;
    margin-top: tokens.$size-l;

    &.hasBreadcrumbs {
      margin-top: tokens.$size-s;
    }
  }
}

.content {
  @include media-queries.mq-m--mf() {
    @include flex.flex-center();
  }
}

.mainContent {
  flex-grow: 1;
}

.sideToolbar {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  margin-top: -30px;
  color: tokens.$color-ink-light;
}

.sideContent {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
}

.upperTitle {
  position: relative;
  font-size: tokens.$font-size-s;
  font-weight: tokens.$font-weight-medium;
  line-height: tokens.$line-height-text;
  color: tokens.$color-secondary-lighter;
  text-transform: uppercase;
  letter-spacing: .03em;
}

.title {
  margin-top: tokens.$size-s;

  .lightTheme & {
    margin-top: 0;
    font-size: tokens.$font-size-heading-2;
    font-weight: tokens.$font-weight-medium;
    line-height: tokens.$line-height-heading;
  }
}

.description {
  max-width: 55ch;
  margin-top: tokens.$size-xxs;
  margin-bottom: tokens.$size-s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .primaryTheme & {
    opacity: .7;
  }
}

.children {
  margin-top: tokens.$size-m;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  max-width: 300px;
  visibility: hidden;

  @include media-queries.mq-m--mf() {
    visibility: visible;
  }
}

.tabs {
  margin-top: tokens.$size-l;

  .primaryTheme & :global(.sas-tab)::after {
    background: tokens.$color-white;
  }

  .lightTheme & :global(.sas-tab.--active) {
    color: tokens.$color-ink;
  }
}

.contentLoader ~ .contentLoader {
  margin-top: tokens.$size-xs;
}

.hasImage {
  @include media-queries.mq-m--mf() {
    padding-right: 312px;
  }
}

.hasTabs {
  padding-bottom: 0;
}

.hasElevation {
  box-shadow: tokens.$shadow-s tokens.$shadow-color;
}

// --------------------------------------------------------------
// Header Themes
// --------------------------------------------------------------

.primaryTheme {
  color: tokens.$color-white;
  background-color: tokens.$color-main;

  :global(.sas-button) {
    color: tokens.$color-white;
  }
}

.lightTheme {
  color: tokens.$color-ink;
  background: tokens.$color-white;
}

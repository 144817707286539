@use "@sas-te/alfabeto-tokens/src/scss/functions";
@use "@sas-te/alfabeto-tokens/dist/tokens";

:global(.sas-dropdown) .item,
:global(.sas-user-menu-dropdown) .item,
.item {
  &:global(.sas-button) {
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    padding: tokens.$size-xxs tokens.$size-s;
    font-size: tokens.$font-size-s;
    font-weight: tokens.$font-weight-base;
    border-radius: 0;

    span {
      max-height: tokens.$size-m;
    }

    &:first-child,
    &:last-child {
      height: 51px;
    }

    &.colorDefault {
      color: tokens.$color-ink-light;
    }

    &.colorPrimary {
      color: tokens.$color-primary;
    }

    &.colorDanger {
      color: tokens.$color-danger;
    }

    &.colorInk{
      color: tokens.$color-ink;
    }

    &:focus {
      box-shadow: none !important;
    }

    &:hover,
    &:focus {
      background-color: tokens.$color-ice;
    }
  }
}

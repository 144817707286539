@use "@sas-te/alfabeto-tokens/dist/tokens";

.selected {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: tokens.$size-xxs;
  background: tokens.$color-primary;
  border-radius: 50%;
}

.icon {
  width: 10px;
  height: 10px;
  stroke: tokens.$color-white;
  stroke-width: 5px;
}

@use "@sas-te/alfabeto-tokens/src/scss/functions";
@use "@sas-te/alfabeto-tokens/dist/tokens";

.formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inline {
  display: flex;
  flex-direction: row;
  width: auto;
}

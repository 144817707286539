@use "@sas-te/alfabeto-tokens/dist/tokens";

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-family: tokens.$font-family-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  transition: all 0.2s ease;
}

@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/flex";
@use "@sas-te/alfabeto-tokens/dist/tokens";

.bodyModalOpen {
  overflow: hidden;
}

.container {
  position: fixed;
  z-index: tokens.$zindex-modal;
  overflow: hidden;
  outline: 0;
  @include flex.flex-center();
  @include mixins.cover(fixed);

  &.--animation-exited {
    transition: transform 300ms ease-in-out 400ms;
  }
}

.backdrop {
  z-index: -1;
  background-color: rgba(tokens.$color-ink, .4);
  transition: opacity 300ms ease-in-out;
  @include mixins.cover(fixed);

  &.--animation-entered,
  &.--animation-exiting {
    opacity: 1;
  }

  &.--animation-entering,
  &.--animation-exited {
    opacity: 0;
  }

  &.--animation-exited {
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out 300ms;
    transform: translateY(100%);
  }
}

.modal {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  transition: transform 300ms ease-in-out;
}

.header {
  display: flex;
  padding: tokens.$size-m tokens.$size-m tokens.$size-xs tokens.$size-m;
}

.titleContainer {
  flex-grow: 1;
}

.title {
  font-size: tokens.$font-size-heading-6;
  font-weight: tokens.$font-weight-semi-bold;
  letter-spacing: .04em;
}

.closeContainer {
  padding-left: tokens.$size-xs;
}

.content {
  flex: 1 1 auto;
  padding: tokens.$size-s tokens.$size-m tokens.$size-m;
  overflow-y: auto;
}

.footer {
  padding: tokens.$size-s tokens.$size-m tokens.$size-m;
}

.actions {
  display: flex;
  padding: tokens.$size-s tokens.$size-s 0 tokens.$size-s;
}

.action {
  padding: 0 tokens.$size-xs;
}

// --------------------------------------------------------------
// Actions Alignment
// --------------------------------------------------------------

.--actions-justify {
  .action {
    flex-basis: 0;
    flex-grow: 1;
  }
}

.--actions-right {
  justify-content: flex-end;
}

// --------------------------------------------------------------
// Sizes
// --------------------------------------------------------------

.--size-large {
  width: 100%;
}

.--size-medium {
  width: 540px;
}

.--size-small {
  width: 352px;
}

// --------------------------------------------------------------
// Margins
// --------------------------------------------------------------

.--margin-large {
  margin: tokens.$size-l;
}

.--margin-medium {
  margin: tokens.$size-m;
}

.--margin-small {
  margin: tokens.$size-s;
}

// --------------------------------------------------------------
// Animation
// --------------------------------------------------------------

.--animation-entering,
.--animation-exited {
  transform: translateY(100%);
}

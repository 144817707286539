@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.mainLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: tokens.$size-xs;

  img {
    width: 73px;
  }
}

.secondaryLogo {
  @extend .mainLogo;

  order: 2;
  padding-right: 45px;
  margin: auto;

  @include media-queries.mq-xl--mf() {
    order: 6;
    padding-right: 0;
    margin: 0;
  }
}

@use "@sas-te/alfabeto-tokens/dist/tokens";

.search {
  padding: tokens.$size-s;
  border-bottom: 1px solid tokens.$color-ink-lightest;
}

.input {
  width: 100%;
}

@use "@sas-te/alfabeto-tokens/dist/tokens";

.box {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: tokens.$size-m;
}

.loadingIcon {
  margin-right: tokens.$size-m;
}

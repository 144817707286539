@use "@sas-te/alfabeto-tokens/src/scss/functions";
@use "@sas-te/alfabeto-tokens/dist/tokens";

.wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: tokens.$size-xs;
  font-size: tokens.$font-size-s;
  font-weight: tokens.$font-weight-medium;
  line-height: tokens.$line-height-input;
  color: tokens.$color-danger;
}

.text {
  flex-grow: 1;
  padding-left: tokens.$size-xs;
}

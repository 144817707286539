@use "@sas-te/alfabeto-tokens/dist/tokens";

.pageState {
  margin: 0 auto;
  text-align: center;
}

.title {
  font-weight: tokens.$font-weight-medium;
  line-height: tokens.$line-height-heading;
  color: tokens.$color-ink-light;
}

.image {
  max-width: 100%;
  margin-bottom: tokens.$size-s;
}

.isMedium {
  max-width: 372px;

  .title {
    font-size: tokens.$font-size-heading-6;
  }
}

.isSmall {
  max-width: 228px;

  .title {
    font-size: tokens.$font-size-heading-6-small;
  }
}

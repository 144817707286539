@use "@sas-te/alfabeto-tokens/dist/tokens";

.tooltip {
  padding: tokens.$size-xxs tokens.$size-s;
  font-size: tokens.$font-size-s;
  font-weight: tokens.$font-weight-medium;
  line-height: tokens.$line-height-input;
  color: tokens.$color-white;
  background-color: tokens.$color-ink;
  border-radius: tokens.$border-radius-xs;

  .caretIcon {
    position: absolute;
  }

  &[data-placement^="top"] > .caret {
    bottom: -5px;
    width: 10px;
    height: 5px;

    .caretIcon {
      top: 0;
      left: 0;
    }
  }
  &[data-placement^="bottom"] > .caret {
    top: -5px;
    width: 10px;
    height: 5px;

    .caretIcon {
      bottom: 0;
      left: 0;
      transform: rotate(180deg);
    }
  }
  &[data-placement^="left"] > .caret {
    right: -5px;
    width: 5px;
    height: 10px;

    .caretIcon {
      top: 2px;
      left: -3px;
      transform: rotate(-90deg);
    }
  }
  &[data-placement^="right"] > .caret {
    left: -5px;
    width: 5px;
    height: 10px;

    .caretIcon {
      top: 2px;
      right: -3px;
      transform: rotate(90deg);
    }
  }
}

@use "@sas-te/alfabeto-tokens/dist/tokens";

.Toastify__toast {
  display: flex;
  align-items: center;
  padding: tokens.$size-s;
  font-size: tokens.$font-size-heading-5-small;
  font-weight: tokens.$font-weight-medium;
  line-height: tokens.$line-height-heading;
  border-radius: tokens.$border-radius-m;

  .Toastify__close-button {
    align-self: center;
  }

  .Toastify__toast-body {
    svg {
      vertical-align: middle;
    }
  }

  &--info {
    color: tokens.$color-primary-dark;
    background: tokens.$color-primary-lightest;

    .Toastify__close-button {
      color: tokens.$color-primary-dark;
    }
  }

  &--success {
    color: tokens.$color-success-darker;
    background: tokens.$color-success-lightest;

    .Toastify__close-button {
      color: tokens.$color-success-darker;
    }
  }

  &--warning {
    color: tokens.$color-warning-darker;
    background: tokens.$color-warning-lightest;

    .Toastify__close-button {
      color: tokens.$color-warning-darker;
    }
  }

  &--error {
    color: tokens.$color-danger-darker;
    background: tokens.$color-danger-lightest;

    .Toastify__close-button {
      color: tokens.$color-danger-darker;
    }
  }

  &-body {
    display: flex;
    flex: 1 1 auto;
  }
}

@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.modal {
  :global(.sas-modal-title) {
    margin-bottom: 0;
    font-size: tokens.$font-size-heading-3-small;

    @include media-queries.mq-xl--mf() {
      font-size: tokens.$font-size-heading-3;
    }
  }

  :global(.sas-modal-actions) {
    :global(.sas-modal-action):last-child {
      text-align: right;
    }
  }
}

.subTitle {
  margin-bottom: tokens.$size-s;
  font-size: tokens.$font-size-heading-6;
  color: tokens.$color-ink-light;
}

.schoolSelector {
  margin-bottom: tokens.$size-xs;
}

.chooseProfileButton {
  @include media-queries.mq-xl--mf() {
    width: 145px;
  }
}

@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.profileImage {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  margin-right: tokens.$size-xs;
  font-size: tokens.$font-size-heading-3-small;
  color: tokens.$color-primary;
  background: tokens.$color-white;
  border: none;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(tokens.$color-black, .25);

  @include media-queries.mq-xl--mf() {
    width: 50px;
    min-width: 50px;
    height: 50px;
    min-height: 50px;
    margin: 0 tokens.$size-xs 0 0;
    font-size: tokens.$font-size-heading-3-small;
    background: tokens.$color-white;
    border: 2px solid tokens.$color-primary;
    border-radius: 50%;
    box-shadow: none;
  }
}

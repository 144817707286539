@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.profile {
  order: -1;

  @include media-queries.mq-xl--mf() {
    order: 2;
  }
}

.dropdown {
  position: absolute;
  top: 68px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: calc(100% - 68px);
  overflow-y: auto;
  background-color: tokens.$color-white;

  li {
    border-bottom: 1px solid tokens.$color-ink-lightest;

    @include media-queries.mq-xl--mf() {
      border-bottom: none;

      &:not(:last-child) {
        border-bottom: none;
      }
    }
  }

  @include media-queries.mq-xl--mf() {
    height: auto;
    max-height: calc(100% - 71px);
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 2px rgba(tokens.$color-black, .034), 0 6px 5px rgba(tokens.$color-black, .048), 0 12px 10px rgba(tokens.$color-black, .06), 0 22px 17px rgba(tokens.$color-black, .072), 0 41px 33px rgba(tokens.$color-black, .086);
  }
}

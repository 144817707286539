@use "@sas-te/alfabeto-tokens/dist/tokens";

.label {
  display: inline-block;
  margin-bottom: tokens.$size-xs;
}

.labelInfoIcon {
  margin-left: tokens.$size-xxs;

  svg {
    position: relative;
    top: -1px;
    width: 14px;
    vertical-align: middle;
  }
}

@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/variables";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";

.loader {
  position: relative;
  display: block;
  max-width: 100%;
  height: tokens.$size-s;
  overflow: hidden;
  background: variables.$skeleton-color;
  border-radius: tokens.$border-radius-xs;
  opacity: 1;
  width: 100%;

  &::before {
    background: linear-gradient(140deg, transparent 25%, variables.$skeleton-highlight 50%, transparent 75%);
    animation-name: pulse;
    animation-duration: 1600ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    @include mixins.pseudo();
    @include mixins.cover(absolute);
  }
}

// --------------------------------------------------------------
// Animation
// --------------------------------------------------------------

@keyframes pulse {
  0% {
    transform: translate3d(-80%, 0, 0);
  }

  100% {
    transform: translate3d(120%, 0, 0);
  }
}

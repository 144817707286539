@use "@sas-te/alfabeto-tokens/dist/tokens";

.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: tokens.$color-ink-lightest;
  border-radius: tokens.$border-radius-circle;
}

.text {
  position: relative;
  z-index: 1;
  font-size: tokens.$font-size-s;
  font-weight: tokens.$font-weight-semi-bold;
  line-height: tokens.$line-height-text;
  color: tokens.$color-ink-light;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: auto;
}

.isLarge {
  min-width: 44px;
  height: 44px;
}

.isMedium {
  min-width: 36px;
  height: 36px;
}

.isSmall {
  min-width: tokens.$size-l;
  height: tokens.$size-l;
}

.userIcon {
  color: tokens.$color-ink-light;
  width: tokens.$size-m;

  .isSmall & {
    width: 18px;
  }
}

@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";

.option {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 6px tokens.$size-xs;
  margin-bottom: tokens.$size-xs;
  cursor: pointer;
  background-color: tokens.$color-white;
  border: 1px solid tokens.$color-ink-lightest;
  border-radius: 20px;
  outline: none;

  &:hover,
  &:focus {
    box-shadow: tokens.$shadow-m tokens.$shadow-color;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.active {
  @include mixins.input-focus-outline();

  &:hover,
  &:focus {
    @include mixins.input-focus-outline();
  }

  :global(.selected) {
    opacity: 1;
  }
}

.initial {
  display: flex;
  align-items: center;
  justify-content: center;
  width: tokens.$size-l;
  height: tokens.$size-l;
  margin-right: tokens.$size-xs;
  font-size: tokens.$font-size-m;
  color: tokens.$color-ink-light;
  text-align: center;
  background-color: tokens.$color-ink-lightest;
  border-radius: 50%;
}

.name {
  display: flex;
  justify-content: flex-start;
  font-size: tokens.$font-size-s;
  color: tokens.$color-ink;
}

@use "@sas-te/alfabeto-tokens/src/scss/functions";
@use "@sas-te/alfabeto-tokens/dist/tokens";

.dropdown {
  z-index: tokens.$zindex-dropdown;
  width: 214px;
  max-width: 100%;
  overflow: hidden;
  text-align: left;
  transition: none;

  &:global(.sas-box) {
    padding: 0;
  }
}

@use "@sas-te/alfabeto-tokens/src/scss/functions";
@use "@sas-te/alfabeto-tokens/dist/tokens";

.pagination {
  display: inline-block;

  :global(.sas-button):disabled {
    &:hover,
    &:focus {
      background: transparent;
    }
  }
}

.page {
  min-width: 34px;
  margin: tokens.$size-xxs;

  &.active {
    background: rgba(tokens.$color-ink, .15);
  }
}

.pageGroup {
  white-space: nowrap;

  .dots:disabled {
    cursor: auto;
  }
}

@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/functions";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

$higher-zindex: tokens.$zindex-tooltip;

.dropdown {
  z-index: tokens.$zindex-dropdown;
  padding: 0;
  overflow: hidden;
  text-align: left;
  transition: none;

  > :first-child {
    border-top-left-radius: tokens.$border-radius-m;
    border-top-right-radius: tokens.$border-radius-m;
  }

  > :last-child {
    border-bottom-right-radius: tokens.$border-radius-m;
    border-bottom-left-radius: tokens.$border-radius-m;
  }

  @include media-queries.mq-s() {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $higher-zindex + 2;
    width: 100%;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    > :first-child {
      border-top-left-radius: 16px !important;
      border-top-right-radius: 16px !important;
    }
  }
}

.dropdownFluid {
  $device-s-plus-1: tokens.$device-s + 1;

  @media all and (min-width: $device-s-plus-1) {
    width: auto;
    min-width: 208px;
    max-width: 354px;
  }
}

.body {
  padding: tokens.$size-s;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $higher-zindex + 1;
  width: 100%;
  height: 100%;
  background-color: tokens.$color-ink;
  opacity: .4;
}

.dropdownHeader {
  display: flex;
  align-items: flex-start;
  padding: {
    top: tokens.$size-s;
    right: tokens.$size-s;
    bottom: tokens.$size-xs;
    left: tokens.$size-s;
  };
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);
}

.dropdownHeaderTitle {
  flex-grow: 1;
  padding-right: tokens.$size-xs;
  font-size: tokens.$font-size-heading-6;
  font-weight: tokens.$font-weight-medium;
  color: tokens.$color-ink;
}

.bodyDropdownOpen {
  overflow: hidden;
}

@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: tokens.$color-white;

  @include media-queries.mq-xl--mf() {
    position: fixed;
    top: 71px;
    height: auto;
    max-height: calc(100% - 71px);
    padding: {
      right: tokens.$size-s;
      bottom: tokens.$size-xs;
      left: 80px;
    };
    box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  }
}

.title {
  margin: tokens.$size-s;
  font-size: tokens.$font-size-heading-4;
  color: tokens.$color-ink;
}

.subMenus {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  padding-left: 0;
  margin: 0;

  @include media-queries.mq-xl--mf() {
    flex-direction: row;
    padding-left: tokens.$size-s;
  }
}

.subMenuItem {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-top: 1px solid tokens.$color-ink-lightest;

  @include media-queries.mq-xl--mf() {
    width: calc(100% / 3);
    border: none;
  }
}

.imageMenu {
  display: none;

  @include media-queries.mq-xl--mf() {
    display: flex;
    width: 72px;
    height: 72px;
    margin-right: tokens.$size-m;
  }
}

.linkSubMenu {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: tokens.$size-xs;

  &:hover {
    background: tokens.$color-ice;
    border-radius: 0;

    @include media-queries.mq-xl--mf() {
      border-radius: tokens.$border-radius-l;
    }
  }
}

.subMenuInfos {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subMenuTitle {
  font-size: tokens.$font-size-m;
  color: tokens.$color-primary !important;
}

.subMenuDescription {
  font-size: tokens.$font-size-s;
  color: tokens.$color-ink-light;
}

.iconSubmenuClose {
  display: none;

  @include media-queries.mq-xl--mf() {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
  }
}

@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.multiProfileSelector {
  margin-bottom: tokens.$size-s;
}

.containerProfile {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin: {
    right: -8px;
    bottom: tokens.$size-s;
    left: -8px;
  }
}

.profileCard {
  margin: {
    right: tokens.$size-xs;
    bottom: tokens.$size-s;
    left: tokens.$size-xs;
  }
}

.SASDatePickerRange .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #c0d9f6;
  border-radius: 0;
}

.SASDatePickerRange .DayPicker-Day--start:not(.DayPicker-Day--outside) {
  background-color: #81b3ed !important;
  border-radius: 8px 0 0 8px;
}

.SASDatePickerRange .DayPicker-Day--end:not(.DayPicker-Day--outside) {
  background-color: #81b3ed !important;
  border-radius: 0 8px 8px 0;
}

.SASDatePickerRange .DayPickerInput-Overlay {
  width: 580px;
}

.SASDatePickerRange .SASDatePickerRange_InputFrom .DayPickerInput-Overlay {
  margin-left: -35px;
}

.SASDatePickerRange .SASDatePickerRange_InputTo .DayPickerInput-Overlay {
  margin-left: -308px;
}

.DayPickerGroup {
  display: flex;
  align-items: center;
}

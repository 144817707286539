@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: tokens.$size-s;
  line-height: tokens.$line-height-reset;
  text-decoration: none;
  cursor: pointer;
  background-color: tokens.$color-white;
  border: 0;
  border-radius: 0;

  &:focus {
    @include mixins.input-focus-outline();
  }

  &:hover {
    background-color: rgba(tokens.$color-black, .1);
  }

  &.isPrimary {
    justify-content: center;
    color: tokens.$color-primary;
  }
}

.icon {
  display: block;
  width: tokens.$size-s;
  height: tokens.$size-s;
  margin-right: tokens.$size-xs;
}

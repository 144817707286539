@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}

.item {
  display: flex;

  &:last-child {
    justify-content: flex-end;

    @include media-queries.mq-s() {
      display: none;
    }
  }
}

.title {
  width: 305px;
  height: tokens.$size-s;
  margin-bottom: tokens.$size-xs;
  border-radius: tokens.$size-m;

  @include media-queries.mq-s() {
    width: 220px;
  }

  @include media-queries.mq-xs() {
    width: 150px;
  }
}

.subtitle {
  width: 114px;
  height: 12px;
  border-radius: tokens.$border-radius-m;
  opacity: .5;
}

.middleItem {
  width: 75px;
  height: tokens.$size-m;
  border-radius: tokens.$size-l;

  @include media-queries.mq-s() {
    width: tokens.$element-size-m;
  }
}

.lastItem {
  width: 99px !important;
  height: tokens.$size-m;
  border-radius: tokens.$size-l;
}

@use "@sas-te/alfabeto-tokens/dist/tokens";

.label {
  margin-left: tokens.$size-s;
  font-size: tokens.$font-size-m;
  font-weight: tokens.$font-weight-regular;
  color: tokens.$color-ink;
}

.disabled {
  color: tokens.$color-ink-lightest;
}

.groupTitle {
  font-size: tokens.$font_size_heading_6;
}

@use "@sas-te/alfabeto-tokens/dist/tokens";

.fade {
  opacity: 0;
  transition: opacity tokens.$speed-slow ease-in;

  &.loaded {
    opacity: 1;
  }
}

@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.profileInfo {
  display: flex;
  width: 242px;
  padding: tokens.$size-xs;
  font-size: tokens.$font-size-s;
  cursor: pointer;
}

.infos {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.type {
  display: flex;
  align-items: center;
  font-size: tokens.$font-size-xxs;
  font-weight: tokens.$font-weight-bold;
  line-height: tokens.$line-height-reset;
  color: tokens.$color-primary;
  text-transform: uppercase;
}

.arrowIcon {
  width: 14px;
  height: 14px;
}

.group {
  display: flex;
  flex-direction: column;

  @include media-queries.mq-xl--mf() {
    flex-direction: row;
    flex-direction: column;
    width: 160px;
  }
}

.userName,
.schoolName {
  margin-right: tokens.$size-xs;
  overflow: hidden;
  font-size: tokens.$font-size-xs;
  color: tokens.$color-ink;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include media-queries.mq-xl--mf() {
    max-width: 138px;
    margin-right: 0;
  }
}

@use "sass:math";

@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/flex";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/spacing";
@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/variables";
@use "@sas-te/alfabeto-tokens/src/scss/functions";

.button {
  position: relative;
  font-weight: tokens.$font-weight-bold;
  line-height: tokens.$line-height-reset;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: none;
  appearance: none;
  @include flex.flex-center($inline: true);

  &:focus {
    outline: 0;
  }

  &:disabled {
    @include mixins.disabled-style();
  }

  &.--loading {
    cursor: progress;
    opacity: 1;
  }
}

.buttonText {
  .--loading & {
    opacity: 0;
    transform: scale(0);
  }
}

// --------------------------------------------------------------
// Button Sizes
// --------------------------------------------------------------

.--large {
  height: variables.$button-size-l;
  font-size: tokens.$font-size-m;
  border-radius: tokens.$border-radius-l;
  @include spacing.space-inset(0 variables.$button-padding-l);
}

.--medium {
  height: variables.$button-size-m;
  font-size: tokens.$font-size-s;
  border-radius: tokens.$border-radius-m;
  @include spacing.space-inset(0 variables.$button-padding-m);
}

.--small {
  height: variables.$button-size-s;
  font-size: tokens.$font-size-s;
  border-radius: tokens.$border-radius-s;
  @include spacing.space-inset(0 variables.$button-padding-s);
}

// --------------------------------------------------------------
// Button Variations
// --------------------------------------------------------------

.--primary {
  color: tokens.$color-white;
  @include mixins.button-style(tokens.$color-primary);
}

.--secondary {
  color: tokens.$color-ink-light;
  border: 1px solid rgba(tokens.$color-ink, .25);
  @include mixins.button-style(tokens.$color-white);
}

.--tertiary {
  color: tokens.$color-ink-light;
  background: transparent;
  box-shadow: none;
  @include spacing.space-inset(0 tokens.$size-xs);

  &:hover {
    background: tokens.$color-ink-lightest;
  }

  &:focus {
    @include mixins.focus-style();
  }
}

.--danger {
  color: tokens.$color-white;
  @include mixins.button-style(tokens.$color-danger);
}

.--link {
  color: tokens.$color-primary;
  background-color: transparent;

  &:hover {
    color: functions.color-contrast(tokens.$color-ice, tokens.$color-primary);
    background-color: tokens.$color-ice;
  }
}

// --------------------------------------------------------------
// States
// --------------------------------------------------------------

.--active {
  background-color: tokens.$color-primary-lightest;
}

// --------------------------------------------------------------
// Container Size
// --------------------------------------------------------------
.--full-width {
  width: 100%;
}

// --------------------------------------------------------------
// Icons
// --------------------------------------------------------------

.--iconOnly {
  padding: 0;

  &.--large {
    width: variables.$button-size-l;
  }

  &.--medium {
    width: variables.$button-size-m;
  }

  &.--small {
    width: variables.$button-size-s;
  }
}

.icon {
  line-height: 0;
  @include flex.flex-center($inline: true);

  .--large & {
    @include mixins.element-icon-size(variables.$button-icon-size-l);
  }

  .--medium & {
    @include mixins.element-icon-size(variables.$button-icon-size-m);

    svg {
      stroke-width: 2.3;
    }
  }

  .--small & {
    @include mixins.element-icon-size(variables.$button-icon-size-s);

    svg {
      stroke-width: 2.5;
    }
  }

  .--loading & {
    opacity: 0;
    transform: scale(0);
  }
}

.prepend {
  .--large & {
    margin-right: math.div(variables.$button-padding-l, 2);
  }

  .--medium & {
    margin-right: math.div(variables.$button-padding-m, 2);
  }

  .--small & {
    margin-right: math.div(variables.$button-padding-s, 2);
  }
}

.append {
  .--large & {
    margin-left: math.div(variables.$button-padding-l, 2);
  }
  .--medium & {
    margin-left: math.div(variables.$button-padding-m, 2);
  }

  .--small & {
    margin-left: math.div(variables.$button-padding-s, 2);
  }
}

.textLoading {
  @include mixins.visually-hidden();
}

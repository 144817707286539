@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.container {
  position: relative;
  flex-grow: 1;
  min-height: 0;

  @include media-queries.mq-xl--mf() {
    display: flex;
    min-width: 0;
  }
}

.menu {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;

  @include media-queries.mq-xl--mf() {
    display: flex;
    overflow: hidden;
  }

  &.hasOverflowRight {
    &::after {
      position: absolute;
      top: 0;
      right: 87px;
      display: block;
      width: tokens.$size-m;
      height: 100%;
      content: "";
      background: linear-gradient(90deg, transparent, tokens.$color-white);
    }
  }
}

.item {
  display: flex;
  border-bottom: 1px solid tokens.$color-ink-lightest;

  @include media-queries.mq-xl--mf() {
    height: 100%;
    margin-right: tokens.$size-xs;
    border-bottom: none;
  }
}

.navMenuItems {
  display: flex;
  align-items: center;
}

.navMenuItem {
  width: tokens.$element-size-m;
  height: tokens.$element-size-m;
  color: tokens.$color-ink;
  cursor: pointer;
  background-color: tokens.$color-ice;
  border: 0;
  border-radius: tokens.$border-radius-m;
  @include mixins.transition(tokens.$speed-fast);

  &:disabled {
    color: tokens.$color-ink-light;
    background-color: transparent;
  }

  &:not(:disabled):hover {
    background: tokens.$color-ink-lightest;
  }

  &:first-child {
    margin-right: tokens.$size-xs;
    margin-left: tokens.$size-xs;
  }

  &:focus {
    @include mixins.input-focus-outline();
  }
}

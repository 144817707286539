@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";
@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

.profileDetails {
  padding: tokens.$size-xs;
  border-bottom: 1px solid tokens.$color-ink-lightest;
}

.profileType {
  margin-bottom: tokens.$size-xs;
  font-size: tokens.$font-size-xs;
  color: tokens.$color-primary;
  text-transform: uppercase;
}

.profileInfo {
  display: flex;
}

.imageInfo {
  position: relative;
  justify-content: center;
  width: 46px;
  height: 46px;
  border: 1px solid tokens.$color-ink-lightest;
  border-radius: 5px;
  box-shadow: 0 .5px 1px rgba(52, 60, 88, .4);
}

.imgProfile {
  position: relative;
  z-index: 1;
  width: 40px;
  height: 40px;
}

.shadow {
  position: absolute;
  right: 0;
  bottom: -16px;
  width: 46px;
  height: 46px;
}

.userInfo {
  padding-left: 7px;
  font-size: tokens.$font-size-xs;
}

.userName {
  font-weight: tokens.$font-weight-extra-bold;
}

@use "@sas-te/alfabeto-tokens/dist/tokens";
@use "@sas-te/alfabeto-tokens/src/scss/mixins";

.profileCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 99px;
  min-height: 94px;
  text-align: center;
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iOTkiIGhlaWdodD0iNjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggb3BhY2l0eT0iLjMiIGQ9Ik05OSA0Mi41QzY5LjUgNTQgMzIuMjQgNjAgMCA2MFY0MEMwIDIxLjE0NCAwIDExLjcxNiA1Ljg1OCA1Ljg1OCAxMS43MTYgMCAyMS4xNDQgMCA0MCAwaDE5YzE4Ljg1NiAwIDI4LjI4NCAwIDM0LjE0MiA1Ljg1OEM5OSAxMS43MTYgOTkgMjEuMTQ0IDk5IDQwdjIuNXoiIGZpbGw9IiNDMEQ5RjYiLz48L3N2Zz4=");
  border-radius: 20px;
  outline: none;
  box-shadow: tokens.$shadow-s tokens.$shadow-color;
  background: {
    position: top center;
    color: tokens.$color-white;
    repeat: no-repeat;
  }
  @include mixins.transition(tokens.$speed-fast);

  .imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    padding: 12px 5px 5px;

    .img {
      width: auto;
      max-width: 35px;
      height: auto;
      max-height: 35px;
    }
  }

  .titleContainer {
    padding: 2px 4px 4px;

    .title {
      display: inline-block;
      font-size: tokens.$font-size-s;
      font-weight: tokens.$font-weight-semi-bold;
      color: tokens.$color-primary-darker;
    }
  }

  &:hover,
  &:focus {
    box-shadow: tokens.$shadow-m tokens.$shadow-color;
    -webkit-tap-highlight-color: transparent;
  }

  &.profileCardSelected {
    box-shadow: 0 0 0 2px tokens.$color-primary;
  }
}

@use "@sas-te/alfabeto-tokens/dist/tokens";

.sas-dropdown-menu {
  &.sas-box {
    min-width: inherit;
  }
}

.select {
  margin-bottom: tokens.$size-s;
}

.filter {
  width: 100%;
  border-bottom: 1px solid tokens.$color-ice;

  .filterAutocomplete {
    width: 100%;
    padding: tokens.$size-xs 12px 0 12px;
    margin: tokens.$size-xs 0;

    :global(.sas-input-field) {
      background: tokens.$color-white;
    }

    .filterAutocompleteIcon {
      left: 10px !important;
    }
  }
}

.contentList {
  z-index: tokens.$zindex-dropdown;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.overflow {
    max-height: 180px;
    overflow-y: auto;
  }

  .dropdownMenuItem {
    flex-shrink: 0;
  }
}

.propDisplay {
  display: block;
  display: flex;
  align-items: center;
  font-size: tokens.$font-size-m;
  color: tokens.$color-ink;
  letter-spacing: .3px;
}

.propValue {
  font-size: tokens.$font-size-s;
  color: tokens.$color-ink-light;
}

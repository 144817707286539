@use "@sas-te/alfabeto-tokens/src/scss/mixins/media-queries";

// ==============================================================
// Variables
// ==============================================================

// Wrapper max-widths
$wrapper-max-width-s: calc(100% - 32px);
$wrapper-max-width-m: calc(100% - 64px);
$wrapper-max-width-l: calc(100% - 80px);
$wrapper-max-width-xl: 1032px;

// ==============================================================
// Styles
// ==============================================================

.wrapper {
  width: 100%;
  max-width: $wrapper-max-width-s;
  margin: 0 auto;

  @include media-queries.mq-m--mf() {
    max-width: $wrapper-max-width-m;
  }

  @include media-queries.mq-l--mf() {
    max-width: $wrapper-max-width-l;
  }

  @include media-queries.mq-xl--mf() {
    max-width: $wrapper-max-width-xl;
  }
}

.fullWidth {
  max-width: 100%;
}
